@font-face{
	font-family: OpenSans-light;
	src: url(../fonts/OpenSans-Light.ttf);
}

.logo {
	width: 159px; 
    height: 230px;
	margin: 13px auto;
}

.auth-input {
	font-family: OpenSans-light;
    font-size: 16px;
    color: #566573;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #4cb29d;
    width: 100%;
}

.auth-input:focus,
.auth-input:active {
	outline:none;
	border-bottom: 2px solid #303F9F;
}

.auth-input.error {
	border-bottom: 2px solid #D32F2F;
}

.auth-input.error + label {
	color: #566573;
}

.auth-label {
	color: #566573;
	-webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: 15px;
    font-size: 16px;
    line-height: 16px;
    position: absolute;
    top: 16px;
    left: 0;
}

.auth-label.active,
.auth-label:focus {
	top: -12px;
    font-size: 12px;
    line-height: 12px;
    color: #B6B6B6;
}

.contenedor {
	font-family: OpenSans-light;
}

.login-password-reset {
	text-decoration: none;
    color: #566573;
    font-size: 16px;
}

.auth-check {
	display: none;
}

.checkbox label {
	display: inline-block;
    cursor: pointer;
    color:#566573;
    position: relative;
    padding: 5px 0px 0px 35px;
    font-size: 1.1em;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; 
}

.checkbox label:before {
	content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 15px;
    top: 8px;
    border-radius: 3px;
    background: none;
    border: 2px solid #4cb29d; 
}

.input-group.checkbox input[type="checkbox"]:checked + label:before {
    display: none; 
}
        
.input-group.checkbox input[type="checkbox"]:checked + label {
    background:#4cb29d;
    color: #f2f2f2;
    padding: 5px 15px; 
}

.btn-submit {
	background: #eb5c57;
    border-radius: 1px;
    border: 2px solid #f2f2f2;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: OpenSans-light;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn-submit:hover {
	background: #4cb29d;
}

.publicidad {
	display:flex;
	flex-direction: column; 
	align-items: center;
	font-family: OpenSans-light;
    color:#566573;
}

.publicidad h1 {
    font-size: 50px;
}

.publicidad p {
    font-size: 15px;
    color:#566573;
}

.btn-more {
	background: #fff;
    border: 3px solid #4cb29d;
    padding: 5px 5px;
    text-decoration: none;
    cursor: pointer;
    color:#566573;
}

.auth-footer {
	font-family: OpenSans-Light;
    color: #566573;
    margin-top: 2%;
    padding-bottom: 2%;
    text-align: center;
}

.publicidad img {
	width: 530px;
}

.auth-a {
    margin: 0 auto;
    text-decoration: none;
    color: #566573;
    font-size: 17px;
}

.auth-p {
    color: #566573;
    font-size: 17px;
    font-family: OpenSans-Light;
    text-align: center;
    margin-bottom: 18px;
}

@media (max-width:1100px){
	.publicidad img {
		width: 411px;
	}
}

@media (max-width:749px){
	.publicidad,
	.auth-footer {
		display: none;
	}

	.logo {
		width: 120px;
        height: 180px;
	}
}
/*# sourceMappingURL=auth.css.map */
